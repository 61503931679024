@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Poppins:wght@300;400&display=swap");

* {
  margin: 0 ;
  padding: 0 ;
  box-sizing: border-box;
}

/* font-family: 'Poppins', sans-serif; */
/* font-family: 'Playfair Display', serif; */

/* //navbar css */
.navbar {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  line-height: 26px;
  /* text-transform: uppercase; */
  /* box-shadow: 0 8px 6px -6px gray; */
  /* --webkit-box-shadow: 0 8px 6px -6px gray; */
  /* border-bottom: solid gray !important; */
  
}
.headerName{
  margin-left:20px;
  margin-right:20px;
}

.nav-link {
  font-weight: 300 !important;
}

.active {
  border-bottom: 2px solid black;
}

.navbar-brand {
  font-weight: 700;
  font-family: "roboto", sans-serif;
  letter-spacing: 3px;
  color:tomato;
}


.search-form {
  margin-right: 50px;
  margin-top: 4px;
}
.search-form input {
  /* border: none; */
  border-radius: 10px;
  width: 330px;
}
.search-form button {
  background-color: #000000;
  border-radius: 10px;
  color: white;
}
.NavLink{
margin-left: 20px;
}


/* ======================footer============= */

.footer {
  margin-top: 20px;
  color: white;
  padding: 25px;
  /* background: #000000; fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #434343,
    #000000
  ); /* Chrome 10-25, Safari 5.1-6 */
  /* background: linear-gradient(to right, #434343, #000000); */
}

.footer a {
  text-decoration: none;
  color: white;
  padding: 10px;
}

.footer a:hover {
  color: #ffefba;
  border-bottom: 1px solid #ffefba;
}
/* ======================= page not found css ======= */
.pnf {
  display: flex;
  min-height: 65vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pnf-title {
  font-size: 100px;
  font-weight: 700;
}

.pnf-heading {
  font-weight: normal;
}

.pnf-btn {
  color: black;
  border: 1px solid black;
  text-decoration: none;
  padding: 10px;
  margin-top: 10px;
}
.pnf-btn:hover {
  background-color: black;
  color: white;
}
/* ========================================= */
/* =========contact us ========= */
.contactus {
  margin: 0;
  padding: 0;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ==================== */
.product-link {
  text-decoration: none !important;
  color: black !important;
}

.cat-btn {
  /* padding: 40px 0px; */
  width: 100%;
  font-size: 24px;
  text-transform: uppercase;
}
.cat-btn:hover {
  background-color: #434343;
  color: white;
}
/* ====================dashboard=================== */

.dashboard {
  /* margin-top: 100px !important; */
  margin-left: 20px;
}

.dashboard-menu h4 {
  background-color: #434343 !important;
  color: white;
  padding: 20px 0px;
}
