.footer-area{
    padding: 40px 0px;
    background-color:black;
    color: white;
}
.footer-area a{
    text-decoration: none;
}
.footer-area .footer-heading{
    font-size: 24px;
    color: whi;
}
.footer-area .footer-underline{
    height: 1px;
    width: 70px;
    background-color: #ffffff;
    margin: 10px 0px;
}
.copyright-area{
    padding: 14px 0px;
    background-color: #666363;
}
.copyright-area p{
    margin-bottom: 0px;
    color: #fff;
    font-size: 16px;
    margin-left: 8px;
}
.social-media{
    text-align: end;
}
.social-media a{
    margin: 0px 10px;
    color: #fff;
    width: 20px;
}
.social-Icons{
    cursor: pointer;
    margin-left: 4px;
    color: #fff;
    display: flex;
  
  
  }
  .icon{
    padding-right: 1px;
    margin-right: 15px;
    justify-content: flex-end;
    text-align: end;
    align-items: end;
    justify-content: end;
    color:white;
  }