.banner-img {
  max-height: 600px;
}

/* .product-card:hover {
  transform: scale(1.1);
} */

.product-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
}


.stock {
  /* position: absolute; */
  top: 0;
  right: 0;
  /* background-color:rgb(255, 0, 0); */
  color: white;
  /* padding: 5px 10px; */
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  width: 81px;
}




.product-card {
  flex-grow: 0;
  flex-shrink: 0;
  margin: 10px;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 5px;
  box-shadow: 0 2px 4px rgba(255, 255, 255, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
  width: 100%;
  height: auto;
  color: #000;
  border-radius: 10px;
  box-shadow: 0px 16px 34px 0px rgba(0,0,0,0.176);
}
.load-div {
  width: 100%;
  padding: 6px;
  height: 50px;
  margin-top: -2px;
  background-color: rgba(255, 255, 255, 1);
  color: #000;
  border-radius: 500px;
  box-shadow: 0px 16px 35px 8px rgba(0,0,0,0.176);
  cursor: pointer;
}

.product-image {
  border: 1px solid rgb(255, 255, 255);
  padding: 0;
  height: auto;
  background: transparent;
  cursor: pointer;
  margin: 1px auto;
}

.product-image img {
  width: 100%;
  height: 150px;
  display: block;
  align-items: center;
}

.product-image img:hover {
  transform: scale(1.1);
}


.product-details {
}

.product-title {
  font-size: 15px;
  font-family: monospace;
  margin-bottom: 5px;
  display: block;
}

.product-price {
  font-weight: bold;
  font-size: 18px;
  color: rgb(0, 0, 0);
  display: inline-flex;
  flex-direction: row;
  margin-right: 50px;

}

.product-offers {
  color: green;
  display: inline-flex;
  flex-direction: row;
  font-family: monospace;

}

.product-actions {
  margin-top: 6px;
}

.home-page button {
  border-radius: 0;
  width: 49%;
  border-radius: 50px;
  font-size: 12px;
  font-weight: bold;
}

.button-24 {
  background: #ffffff;
  border: 1px solid #FF4742;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #000000;
  cursor: pointer;
  display: inline-block;
  font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
  font-size: 12px;
  font-weight: 800;
  line-height: 16px;
  min-height: 40px;
  outline: 0;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  margin-right: 4px;
  width: 45%;

}

.button-24:hover,
.button-24:active {
  background-color: initial;
  background-position: 0 0;
  color: #FF4742;
}

.button-24:active {
  opacity: .5;
}


.button-25 {
  background: #ffffff;
  border: 1px solid #FF4742;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #000000;
  cursor: pointer;
  display: inline-block;
  font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
  font-size: 10px;
  font-weight: 800;
  line-height: 16px;
  min-height: 40px;
  outline: 0;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: 45%;
}

.button-25:hover,
.button-25:active {
  background-color: initial;
  background-position: 0 0;
  color: #FF4742;
}

.button-25:active {
  opacity: .5;
}



@media only screen and (max-width: 568px) {

  .banner-img {
    height: 240px;
  }

  .line {
    font-size: 10px;
  }
  .product-card {
    flex-basis: calc(50% - 20px);
    padding: 6px;
  }

  .load-div {
    width: 100%;
    padding: 0px;
    height: 42px;
    margin-top: -2px;
    background-color: rgba(255, 255, 255, 1);
    color: #000;
    border-radius: 500px;
    box-shadow: 0px 16px 35px 8px rgba(0,0,0,0.176);
    cursor: pointer;
}

  /* .card {
    max-width: 176px;
    height: 100px;
  } */

  .card-img-top {
    height: 400px;
    width: 80px;
    padding: 3px;
    position: relative;
    overflow: hidden;
  }

  .home-page button {
    border-radius: 0;
    width: 45%;
    border-radius: 50px;
    font-size: 7px;
    font-weight: bold;
    padding: 4px;
    margin-left: none;
    margin-top: 3px;
  }

  .home-page .add-to-cart-button {
    width: 45%;
    font-size: 10px;
    height: 30px;


  }

  .product-image {
    padding: 0;
    background: transparent;
    cursor: pointer;
    max-width: 250px;
    height: 100px;
  }

  .product-image img {
    width: 96%;
    height: 130px;
    margin: 0 auto;
  }

  .product-details {
    margin-top: 1px;
  }

  .product-title {
    font-size: 8px;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 2px;
    display: block;
  }

  .product-price {
    font-weight: bold;
    font-size:8px;
    color: rgb(0, 0, 0);
    display: inline-flex;
    flex-direction: row;
    margin-right: 10px;

  }

  .product-offers {
    color: green;
    font-size: 8px;
    display: inline-flex;
    flex-direction: row;
  }

  .product-actions {
    margin-top: 2px;
  }

  .button-24 {
    background: #ffffff;
    border: 1px solid #FF4742;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
    box-sizing: border-box;
    color: #000000;
    cursor: pointer;
    display: inline-block;
    font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
    font-size: 10px;
    font-weight: 800;
    line-height: 16px;
    min-height: 22px;
    outline: 0;
    border-radius: 9px 0 9px 0;
    padding: 2px 4px;
    text-align: center;
    text-rendering: geometricprecision;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    margin-right: 4px;
  }
  
  .button-24:hover,
  .button-24:active {
    background-color: initial;
    background-position: 0 0;
    color: #FF4742;
  }
  
  .button-24:active {
    opacity: .5;
  }
  
  
  .button-25 {
    background: #ffffff;
    border: 1px solid #FF4742;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
    box-sizing: border-box;
    color: #000000;
    cursor: pointer;
    display: inline-block;
    font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
    font-size: 8px;
    font-weight: 800;
    line-height: 16px;
    min-height: 22px;
    outline: 0;
    border-radius: 9px 0 9px 0;

    padding: 2px 4px;
    text-align: center;
    text-rendering: geometricprecision;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
  }
  
  .button-25:hover,
  .button-25:active {
    background-color: initial;
    background-position: 0 0;
    color: #FF4742;
  }
  
  .button-25:active {
    opacity: .5;
  } 

}



/* Additional styles for laptops and larger screens */
@media only screen and (min-width: 1024px) {
  .product-card {
    flex-basis: calc(20% - 20px);  }
}

.hidden {
  opacity: 0;
  transform: translateY(50px);
  /* Initial position below viewport */
  transition: opacity 1s ease, transform 2s ease;
}

.visible {
  opacity: 1;
  transform: translateY(0);
  /* Moves to the default position */
}



.home-page h1 {
  color: rgb(255, 255, 255);
  font-family: "Playfair Display", serif;
}

.navbarHeader {
  margin-right: none;
}

.home-page {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: none;
}


/* ==================
filters
============== */
.filters h4 {
  margin-top: 100px;
  color: gray !important;
  border-bottom: 1px solid rgba(128, 128, 128, 0.337);
}

.ant-checkbox-wrapper:first-of-type {
  margin-left: 8px;
}

.ant-checkbox-wrapper {
  margin: 2px;
}

.ant-radio-wrapper {
  margin-bottom: 5px !important;
  margin-left: 10px;
}

.filters button {
  background-color: black;
  width: 100%;
  border: none;
  border-radius: 0;
  margin-top: 20px;
}


.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
  background: transparent !important;
}

.line-by-line {
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
  /* -webkit-animation-name: fadeInUp; */
  /* animation-name: fadeInUp; */
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.line {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s ease-in-out forwards;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.line-prod:hover {
  opacity: 0;
  transform: translateY(2px);
  animation: fadeInUp 0.5s ease-in-out forwards;
  -webkit-animation-delay: 0.01s;
  animation-delay: 0.1s;
}

/* Delayed fade-in animation for the entire block */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* Delayed fade-in animation for each line */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Delay the animations for each line */
.line:nth-child(1) {
  animation-delay: 0.5s;
  -webkit-animation-delay: 0.3s;
  /* animation-delay: 0.3s; */
}

.line:nth-child(2) {
  animation-delay: 1s;
  -webkit-animation-delay: 0.6s;

}

.line:nth-child(3) {
  animation-delay: 1.5s;
  -webkit-animation-delay: 0.9s;

}

.line:nth-child(4) {
  animation-delay: 1.5s;
}

.even-card {
  /* width: 600px; */
  margin-left: 30%;
  text-align: center;
  margin-right: -7%;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.odd-card {
  /* width: 600px; */
  margin-right: 30%;
  margin-left: -7%;
  text-align: left;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

/* Adjust the font size and other properties for the previous button */
.carousel-control-prev {
  font-size: 24px;
  background-color: rgba(0, 0, 0, 0.1);
  width: 40px;

}

/* Adjust the font size and other properties for the next button */
.carousel-control-next {
  font-size: 24px;
  background-color: rgba(0, 0, 0, 0.1);
  width: 40px;

}




.spinner-box {
  width: 44px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  margin-left: 100px;
}

/* SPINNING CIRCLE */

.leo-border-1 {
  position: absolute;
  width: 50px;
  height: 50px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgb(63,249,220);
  background: linear-gradient(0deg, rgba(63,249,220,0.1) 33%, rgba(63,249,220,1) 100%);
  animation: spin3D 1.8s linear 0s infinite;
}

.leo-core-1 {
  width: 50px;
  height: 50px;
  /* background-color: #ffffffaa; */
  border-radius: 50%;
}

.leo-border-2 {
  position: absolute;
  width: 50px;
  height: 50px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgb(251, 91, 83);
  background: linear-gradient(0deg, rgba(251, 91, 83, 0.1) 33%, rgba(251, 91, 83, 1) 100%);
  animation: spin3D 2.2s linear 0s infinite;
}

.leo-core-2 {
  width: 50px;
  height: 50px;
  background-color: #1d2630aa;
  border-radius: 50%;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to{
    transform: rotate(359deg);
  }
}

@keyframes spin3D {
  from {
    transform: rotate3d(.5,.5,.5, 360deg);
  }
  to{
    transform: rotate3d(0deg);
  }
}

@keyframes configure-clockwise {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes configure-xclockwise {
  0% {
    transform: rotate(45deg);
  }
  25% {
    transform: rotate(-45deg);
  }
  50% {
    transform: rotate(-135deg);
  }
  75% {
    transform: rotate(-225deg);
  }
  100% {
    transform: rotate(-315deg);
  }
}

@keyframes pulse {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: .25;
    transform: scale(.75);
  }
}


.star-rating {
  display: flex;
  justify-content: center;
}

.star {
  margin: 0 2px; 
}

.filled {
  width: 7px; 
  height: 7px; 
  background-color: #ddd; 
}

.filled-star {
  background-color: rgb(248, 220, 63); 
}

