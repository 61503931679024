.user_data{
    width: 74% !important;
    height:100%;
    padding: 0.2rem;
    background: rgb(255, 255, 255);
}

span{
    font-weight: 700;
    color: black;
}
.admin_users{
    background-color: #ffffff;
}
.admin_icons{
    width: 8rem;
    font-size:24px;
}


.list-group-item{
    padding: 1.5rem;
    width: 99%;
    height: 80px;
    font-size: 17px;
    margin-left: 1px;
}

.title{
    margin-top: 40px;
   }
      
   .btn_update{
    margin-top: 10px;
   }

   .userD{
    margin-top: 30px;
    width: 99%;
}





@media only screen and (max-width: 580px) {
    .user_data{
        width: 100%;
        height:100%;
        background: rgb(255, 255, 255);
        margin-top: 2rem;
        margin-left: 4px;
    }
    .user_menu{
        width: 100%;
        height:65px;
        margin-left: auto;
        padding: 3px;
    }
    .dashboard-menu{
        width: 100px;
    }

    .dashboard-menu h4{
    background-color: #030303 !important;
    color: white;
    padding: 19px 0px;
    width: 100px;
    }

    .list-group {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        width: 103px;
    }
    .list-group-item{
        padding: 1.5rem;
        width: 120px;
        height: 57px;
        font-size: 10px;
        margin-left: 5px;
    }
  
    span{
        font-weight: 300;
        color: black;
    }


    .admin_icons{
        width: 70;
        font-size:18px;
    }
    .userD{
        margin-top: 30px;
        width: 99%;
    }
   .title{
    margin-top: 50px;
   }
   .form-size{
    width: 99%;
}
}


.form-size{
    width: 99%;
}


