@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
.login_page{
  display: grid;
  height: 500px;
  width: 100%;
  place-items: center;
  /* background: #f2f2f2; */
}
.forgoot_page{
  display: grid;
  height: 650px;
  width: 100%;
  place-items: center;
  /* background: #f2f2f2; */
}
::selection{
  background: #4158d0;
  color: #fff;
}
.wrapper{
  width:35%;
  background: #fff;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 1);
  color: #000;
  border-radius: 10px;
  box-shadow: 0px 16px 48px 23px rgba(0,0,0,0.176);
}
.title_head{
  font-size: 35px;
  font-weight: 600;
  text-align: center;
  line-height: 100px;
  color: #fff;
  user-select: none;
  border-radius: 15px 15px 0 0;
  background: linear-gradient(-135deg, #c850c0, #4158d0);
}
.wrapper form{
  padding: 10px 30px 50px 30px;
}
.wrapper form .field{
  height: 50px;
  width: 100%;
  margin-top: 20px;
  position: relative;
}
.wrapper form .field_otp{
  height: 50px;
  width: 33%;
  margin-top: 20px;
  border-radius: 50%;
  position: relative;
}
.form-control-otp{
  width: 55%;
  height: 47px;
  border-radius: 15px 15px 15px 15px;
  margin-top: 10px;
  margin-left: 8px;
  display: inline;
}
.field_veryfi_otp{
width:40px;
}
.otp_btn{
  height: 44px;
}


.wrapper form .field input{
  height: 100%;
  width: 100%;
  outline: none;
  font-size: 17px;
  padding-left: 20px;
  border: 1px solid lightgrey;
  border-radius: 25px;
  transition: all 0.3s ease;
}
.wrapper form .field input:focus,
form .field input:valid{
  border-color: #4158d0;
}
.wrapper form .field label{
  position: absolute;
  top: 50%;
  left: 20px;
  color: #999999;
  font-weight: 400;
  font-size: 17px;
  pointer-events: none;
  transform: translateY(-50%);
  transition: all 0.3s ease;
}
form .field input:focus ~ label,
form .field input:valid ~ label{
  top: 0%;
  font-size: 16px;
  color: #4158d0;
  background: #fff;
  transform: translateY(-50%);
}
form .content{
  display: flex;
  width: 100%;
  height: 50px;
  font-size: 16px;
  align-items: center;
  justify-content: space-around;
}
form .content .checkbox{
  display: flex;
  align-items: center;
  justify-content: center;
}
form .content input{
  width: 15px;
  height: 15px;
  background: red;
}
form .content label{
  color: #262626;
  user-select: none;
  padding-left: 5px;
}
form .content .pass-link{
  color: "";
}
form .field input[type="submit"]{
  color: #fff;
  border: none;
  padding-left: 0;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  background: linear-gradient(-135deg, #c850c0, #4158d0);
  transition: all 0.3s ease;
}
form .field input[type="submit"]:active{
  transform: scale(0.95);
}
form .signup-link{
  color: #262626;
  margin-top: 20px;
  text-align: center;
}
form .pass-link a,
form .signup-link a{
  color: #4158d0;
  text-decoration: none;
}
form .pass-link a:hover,
form .signup-link a:hover{
  text-decoration: underline;
}





/* register page*/
.register_page{
  display: grid;
  height: 610px;
  width: 100%;
  place-items: center;
}

.register_wrapper{
    width:40%;
    background: #fff;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 1);
    color: #000;
    border-radius: 10px;
    box-shadow: 0px 16px 48px 23px rgba(0,0,0,0.176);
}

.register_wrapper .title{
  font-size: 35px;
  font-weight: 600;
  text-align: center;
  line-height: 100px;
  color: #fff;
  user-select: none;
  margin-top: 0;
  border-radius: 15px 15px 0 0;
  background: linear-gradient(-135deg, #c850c0, #4158d0);
}
.register_wrapper .form{
}
.register_wrapper .form .field{
  height: 50px;
  width: 40%;
  margin-top: 20px;
  margin-left: 3rem;
  display: inline-flex;
  position: relative;
  
}
.register_wrapper .form .field{
  height: 50px;
  width: 33%;
  margin-top: 20px;
  border-radius: 50%;
  position: relative;
}
.register_wrapper .form .field input{
  height: 100%;
  width: 100%;
  outline: none;
  font-size: 13px;
  border: 1px solid lightgrey;
  border-radius: 25px;
  transition: all 0.3s ease;
}
.submit_btn{
  background: linear-gradient(-135deg, #c850c0, #4158d0);
  color: white;
 

}
.register_wrapper .form .field input:focus,
.form .field input:valid{
  border-color: #4158d0;
}
.register_wrapper .form .field label{
  position: absolute;
  top: 50%;
  left: 20px;
  color: #999999;
  font-weight: 400;
  font-size: 17px;
  pointer-events: none;
  transform: translateY(-50%);
  transition: all 0.3s ease;
}


.Register_Header{
  text-align: center;
  margin-left: 9rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}






@media only screen and (max-width: 568px) {
  .login_page{
    display: grid;
    height: 500px;
    width: 100%;
    place-items: center;
    /* background: #f2f2f2; */
  }
  .forgoot_page{
    display: grid;
    height:auto;
    width: 100%;
    place-items: center;
  }

  .wrapper{
    width: 350px;
    background: #fff;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 1);
    color: #000;
    border-radius: 10px;
    box-shadow: 0px 16px 48px 23px rgba(0,0,0,0.176);
  }
  .wrapper form .field{
    height: 50px;
    width: 100%;
    margin-top: 20px;
    position: relative;
  }
  .wrapper form .field input{
    height: 100%;
    width: 100%;
    outline: none;
    font-size: 12px;
    border: 1px solid lightgrey;
    border-radius: 25px;
    transition: all 0.3s ease;
  }
  .wrapper form .field_otp{
    height: 50px;
    width: 33%;
    margin-top: 20px;
    border-radius: 50%;
    position: relative;
  }
  .form-control-otp{
    width: 53%;
    height: 47px;
    border-radius: 15px 15px 15px 15px;
    margin-top: 10px;
    margin-left: 15px;
    display: inline;
  }
  .field_veryfi_otp{
  width:40px;
  }
  .otp_btn{
    color: #fefefe;
    font-weight: 600;
    height: 44px;
    border-radius: 10px 10px 10px 10px;

  }
  
  
  
  /* register page*/
.register_page{
  display: grid;
  height: auto;
  width: 100%;
  place-items: center;
}

.register_wrapper{
    width: 80%;
    background: #fff;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 1);
    color: #000;
    border-radius: 10px;
    box-shadow: 0px 16px 48px 23px rgba(0,0,0,0.176);
    overflow: hidden;
    margin-top: 10px;
    margin-bottom: 10px;
}
.register_wrapper .title{
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  line-height: 60px;
  color: #fff;
  user-select: none;
  border-radius: 15px 15px 0 0;
  margin-top: 0;
  background: linear-gradient(-135deg, #c850c0, #4158d0);
}
.register_wrapper .form{
}
.register_wrapper .form .field{
  height: 40px;
  width: 43%;
  margin-top: 20px;
  margin-left: 2rem;
  display: inline-flex;
  position: relative;
}
.register_wrapper .form .field_otp{
  height: 50px;
  width: 33%;
  margin-top: 20px;
  border-radius: 50%;
  position: relative;
}
.register_wrapper .form .field input{
  height: 100%;
  width: 100%;
  outline: none;
  font-size: 10px;
  padding-left: 20px;
  border: 1px solid lightgrey;
  border-radius: 25px;
  transition: all 0.3s ease;
}
.register_wrapper .form .field input:focus,
.form .field input:valid{
  border-color: #4158d0;
}
.register_wrapper .form .field label{
  position: absolute;
  top: 50%;
  left: 20px;
  color: #999999;
  font-weight: 400;
  font-size: 17px;
  pointer-events: none;
  transform: translateY(-50%);
  transition: all 0.3s ease;
}




}





.submit_bt{
  width: 90%;
  height: 40px;
  outline: none;
  font-size: 10px;
  border: 1px solid lightgrey;
  border-radius: 25px;
  transition: all 0.3s ease;
  margin: 0 auto;
  margin-top: 10px;
    margin-left: 23px;
    background: linear-gradient(-135deg, #c850c0, #4158d0);
    color: white;
}



