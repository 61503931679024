.col-md-12{
    background-color: rgba(255, 255, 255, 1);
    color: #000;
    border-radius: 10px;
    box-shadow: 0px 16px 22px -4px rgba(0,0,0,0.176);
    width: 98%;
    margin: 5px;
    /* margin: 0 auto; */
    /* padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 10px;
    margin-bottom: 10px; */

}
/* .col-md-11{
    background-color: rgba(255, 255, 255, 1);
    color: #000;
    border-radius: 10px;
    box-shadow: 0px 16px 22px -4px rgba(0,0,0,0.176);
    width: 98%;
    margin: 0 auto;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
} */

.box-1{
    border: 1px solid gray;
    width: 30%;
    margin: 0 auto;

}

.box-2{
    border: 1px solid gray;
    width: 26%;
    margin: 0 auto;

}

.box-3{
    border: 1px solid gray;
    width: 20%;
    margin: 0 auto;
 
}
.btn-light{
    background-color: rgba(255, 255, 255, 1);
    color: #000;
    border-radius: 10px;
    box-shadow: 0px 16px 22px -4px rgba(0,0,0,0.176);
    height: auto;
    width: 100%;
}
.order-image{
    height: 40px;
    width: 40px;
    display: inline-block;
    float: left;
    margin-left: 10px;
    margin-right: 10px;
}




/* @media (min-width: 568px) {
    
    .box-1 {
        border: 1px solid gray;
        width: 90%;
        margin: 0 auto;
    }
    .box-2 {
        border: 1px solid gray;
        width: 90%;
        margin: 0 auto;
    }
    .box-3 {
        border: 1px solid gray;
        width: 90%;
        margin: 0 auto;
    }
} */